import { defineStore } from 'pinia';
import JwlApi from '@base/store/JwlApi';
import Concentrations from '@base/store/Concentrations';

export const api = new JwlApi();

export function handleErrorResponse (e) {
  const rejectReason = {
    trans: true,
    message: 'network.error.configurationError',
    error: e,
    response: e.response,
  };

  if (e.response) {
    rejectReason.message = e.response.data.detail;
    rejectReason.trans = false;
  } else if (e.request) {
    rejectReason.message = 'network.error.serverError';
  }

  return rejectReason;
}

export const useModuleStore = defineStore('module', {
  // convert to a function
  state: () => ({
    currentUser: null,
    isNewUser: false,
    menuNodes: [],
    hasUserData: false,
    baseConcentrations: null,
    mode: 'lms',
    publicInfo: null,
    errors: [],
    version: '5.6.0-automatic-mails',
    maxFileSize: '512mb',
    basicSystemData: null,
    locale: 'en',
  }),
  getters: {},
  actions: {
    HANDLE_LOGIN (data) {
      api.login(data.token, data.refreshToken);
    },
    SET_PUBLIC_INFO (payload) {
      this.publicInfo = payload;
    },
    SET_SYSTEM_MODE (payload) {
      this.mode = payload;
    },
    SET_CURRENT_USER (payload) {
      if (payload) {
        this.currentUser = payload;
        this.hasUserData = true;
      } else {
        this.currentUser = null;
        this.hasUserData = false;
      }
    },
    SET_IS_NEW_USER (payload) {
      this.isNewUser = payload;
    },
    SET_MENU (payload) {
      this.menuNodes = payload;
    },
    SET_BASE_CONCENTRATIONS (payload) {
      this.baseConcentrations = new Concentrations(payload);
    },
    SET_BASE_CONCENTRATIONS_ERROR (err) {
      const concentrations = new Concentrations(null);
      concentrations.error = err;
      this.baseConcentrations = concentrations;
    },
    SET_USER_CLCS (payload) {
      if (this.currentUser) {
        this.currentUser.clcs = payload;
      }
    },
    REPORT_ERROR (payload) {
      const newError = {
        error: payload,
        route: window.location.toString(),
        timestamp: new Date(),
      };
      this.errors.push(newError);
    },
    CLEAR_ERRORS () {
      this.errors = [];
    },
    SET_BASIC_SYSTEM_DATA (payload) {
      this.basicSystemData = payload;
    },
    setPublicInfo (url) {
      return new Promise((resolve, reject) => {
        api.publicAxios.get(`/public-api/1.0${url}`)
          .then((response) => {
            this.SET_PUBLIC_INFO(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    SET_LOCALE (locale) {
      this.locale = locale;
    },
    getData (url) {
      return new Promise((resolve, reject) => {
        api.axios.get(`/api/${this.locale}/${url}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(handleErrorResponse(e));
          });
      });
    },
    postData ({ url, formData }) {
      return new Promise((resolve, reject) => {
        api.axios({
          method: 'post',
          url: `/api/${this.locale}/${url}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          resolve(response.data);
        }).catch((e) => {
          reject(handleErrorResponse(e));
        });
      });
    },
    postPublicData ({ url, formData }) {
      return new Promise((resolve, reject) => {
        api.publicAxios({
          method: 'post',
          url: `/public-api/1.0/${url}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          resolve(response.data);
        }).catch((e) => {
          reject(handleErrorResponse(e));
        });
      });
    },
    getBaseData (forceConcentrations = false) {
      const forSis = this.mode === 'sis';
      return new Promise((resolve, reject) => {
        api.axios.get(`/api/general/current-user/${forSis ? 'sis' : 'lms'}`)
          .then((response) => {
            this.SET_CURRENT_USER(response.data.user);
            this.SET_MENU(response.data.menu);
            this.SET_BASIC_SYSTEM_DATA(response.data.basicSystemData);
            if ((!this.baseConcentrations && this.mode === 'lms') || forceConcentrations) {
              this.getBaseConcentrationsData();
            }
            resolve(response.data.user.role);
          })
          .catch((exception) => reject(handleErrorResponse(exception)));
      });
    },
    getBaseConcentrationsData (forUser = null) {
      return new Promise((resolve, reject) => {
        const requestUrl = forUser
          ? `/api/general/concentrations-personalized/${forUser}`
          : '/api/general/concentrations';
        api.axios.get(requestUrl)
          .then((response) => {
            this.SET_BASE_CONCENTRATIONS(response.data);
            resolve();
          })
          .catch((e) => {
            const err = handleErrorResponse(e);
            this.SET_BASE_CONCENTRATIONS_ERROR(err.message);
            reject(handleErrorResponse(e));
          });
      });
    },
    deprecatedLogInUser ({ username, password }) {
      return new Promise((resolve, reject) => {
        api.deprecatedLogin(username, password)
          .then(() => {
            this.getBaseData()
              .then(() => {
                resolve();
              })
              .catch((e) => {
                reject(handleErrorResponse(e));
              });
          })
          .catch((exception) => {
            let rejectReason = 'There is an error with the configuration.';
            if (exception.response) {
              rejectReason = 'Username or Password incorrect.';
            } else if (exception.request) {
              rejectReason = 'There is an error with the Server.';
            }

            reject(rejectReason);
          });
      });
    },
    logOutUser () {
      api.logout();
      this.SET_CURRENT_USER(null);
      document.location.reload();
    },
    forgotPassword ({ usernameOrEmail, locale }) {
      return new Promise((resolve, reject) => {
        api.publicAxios.post('/forgot-password/init', {
          usernameOrEmail,
          locale,
        }).then(() => {
          resolve();
        }).catch((exception) => {
          reject(handleErrorResponse(exception));
        });
      });
    },
    getImpersonators (searchString) {
      return new Promise((resolve, reject) => {
        api.axios.post(`/api/general/impersonators/${searchString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((exception) => reject(exception));
      });
    },
    getPublicInfo (url) {
      return new Promise((resolve, reject) => {
        api.publicAxios.get(`/public-api/1.0${url}`)
          .then((response) => {
            this.SET_PUBLIC_INFO(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
});

export default useModuleStore;
